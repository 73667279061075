import './App.css';
import { BrowserRouter, Route, Routes, Link as RouterLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Dashboard from './Dashboard';
import DataFetcher from './utils/DataFetcher';
import Login from './Login';
import PrivateRoute from './PrivateRoute';
import DataExport from './DataExport';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Homepage from './Homepage';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CampaignLauncher from './CampaignLauncher';

const drawerWidth = 200;

const menu = [
	{
		route: "/",
		title: "Home",
		component: <Homepage />
	},
	{
		route: "/dashboard",
		title: "Dashboard",
		component: <Dashboard />
	},
	{
		route: "/campaign",
		title: "Campaign Manager",
		component: <CampaignLauncher />
	},
	{
		route: "/export",
		title: "Export Orders",
		component: <DataExport />
	}
]

function App() {
	const dataFetcher = new DataFetcher();

	const [usingLiveDb, setusingLiveDb] = useState(false);
	
	useEffect(() => {
		console.log("Fetching val");
		dataFetcher.getUseLiveDb().then(val => {
			setusingLiveDb(val.useLiveDb);
		});
	}, []);


	function liveDbToggleChange(ev) {
		console.log(`Setting usingLiveDb to ${ev.target.checked}`);
		dataFetcher.setUseLiveDb(ev.target.checked);
		setusingLiveDb(ev.target.checked);
	}

	return (
		<div className="App">
			<BrowserRouter>
				<h1>Application</h1>
				<CssBaseline />
				<AppBar
					position="fixed"
					sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
				>
					<Toolbar>
						<Typography variant="h6" noWrap component="div">
							Coffee Text Management Tool
						</Typography>
						<FormControlLabel
							control={
								<Switch checked={usingLiveDb} onChange={liveDbToggleChange} color={"secondary"} name="liveDb" />
							}
							style={{
								marginLeft: "auto"
							}}
							label="Live database"
						/>
					</Toolbar>
				</AppBar>
				<Drawer
					sx={{
						width: drawerWidth,
						flexShrink: 0,
						'& .MuiDrawer-paper': {
							width: drawerWidth,
							boxSizing: 'border-box',
						},
					}}
					variant="permanent"
					anchor="left"
				>
					<Toolbar />
					<Divider />
					<List>
						{menu.map((item, index) => (
							<Link to={item.route} key={index} component={RouterLink} style={{ textDecoration: 'none' }} >
								<ListItem button key={index}>
									<ListItemText primary={item.title} />
								</ListItem>
							</Link>
						))}
					</List>
				</Drawer>
				<Box
					component="main"
					sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, marginLeft: drawerWidth + 'px' }}
				>
					<Routes>
						<Route exact path='/dashboard' element={<PrivateRoute />}>
							<Route exact path='/dashboard' element={<Dashboard />} />
						</Route>
						<Route exact path='/campaign' element={<PrivateRoute />}>
							<Route exact path='/campaign' element={<CampaignLauncher />} />
						</Route>
						<Route exact path='/export' element={<PrivateRoute />}>
							<Route exact path='/export' element={<DataExport />} />
						</Route>
						<Route exact path='/' element={<PrivateRoute />}>
							<Route exact path='/' element={<Homepage />} />
						</Route>
						<Route exact path='/login' element={<Login />} />
					</Routes>
				</Box>

			</BrowserRouter>
		</div>
	);
}

export default App;
