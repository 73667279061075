import React from 'react';
import AuthStorage from './utils/AuthStorage';
import DataFetcher from './utils/DataFetcher';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container, Divider } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

function padDateInt(dateInt) {
    return dateInt.toString().padStart(2, '0');
}

class Homepage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            userCount: -1,
            textsSent: -1,
            ordersConfirmedThisWeek: -1,
            users: [],
        };

        this.cols = [
            { field: 'name', headerName: 'Name', width: 250 },
            { field: 'phone', headerName: 'Phone', width: 150 },
            // { field: 'address', headerName: 'Address', width: 100 },
            { field: 'email', headerName: 'Email', width: 200 },
            { field: 'created', headerName: 'Registration', width: 200, valueFormatter: (params) => {
                // console.log(timestamp.getValue());
                // console.log(new Date(parseInt(timestamp)*1000));
                console.log(params);
                const d = new Date(parseInt(params.value)*1000);
                return `${padDateInt(d.getDate())}/${padDateInt(d.getMonth())}/${d.getFullYear()} ${padDateInt(d.getHours())}:${padDateInt(d.getMinutes())}`
            } },
            
            { field: 'paymentValidated', headerName: 'Payment Registered', width: 250, renderCell: (params) => {
                if (params.value) {
                    return <CheckIcon />
                } else {
                    return <CloseIcon />
                }
            } },
        ];

        this.authStorage = new AuthStorage();
        this.dataFetcher = new DataFetcher();
    }

    componentDidMount() {
        this.dataFetcher.fetchStats().then(stats => {
            this.setState({
                userCount: stats.registeredUsers,
                textsSent: stats.textsSent,
                ordersConfirmedThisWeek: stats.ordersConfirmedThisWeek,
                users: stats.users.map(user => {
                    return {
                        id: user._id,
                        name: user.name,
                        phone: user.phone,
                        email: user.email,
                        address: user.address.line1,
                        created: user.created,
                        paymentValidated: user.paymentValidated,
                    }
                }),
                loading: false,
            });
        });
    }

    pluralise(count) {
        return count == 1 ? '' : 's';
    }

    render() {
        console.log(this.state.users);
        return (
            <Box>
                <Typography variant="h3" component="div" gutterBottom>
                    Welcome back, {this.authStorage.getUsername()}
                </Typography>
                <Divider />
                {this.state.loading ?
                    <CircularProgress /> :
                    <div>

                        <Container>
                            <ul className="noBulletPoint" style={{ fontSize: 22 }}>
                                <li>{this.state.userCount} registered user{this.pluralise(this.state.userCount)}</li>
                                <li>{this.state.textsSent} text{this.pluralise(this.state.textsSent)} sent</li>
                                <li>{this.state.ordersConfirmedThisWeek} confirmed order{this.pluralise(this.state.ordersConfirmedThisWeek)} this week</li>
                            </ul>
                            {/* <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>User</TableCell>
                                        <TableCell align="right">Email</TableCell>
                                        <TableCell align="right">Phone</TableCell>
                                        <TableCell align="right">Address</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.users.map((user) => (
                                        <TableRow
                                            key={user.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {user.name}
                                            </TableCell>
                                            <TableCell align="right">{user.email}</TableCell>
                                            <TableCell align="right">{user.phone}</TableCell>
                                            <TableCell align="right">{user.address.line1}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> */}
                        </Container>
                        <div style={{ display: 'flex', height: '500px' }}>
                            <div style={{ flexGrow: 1 }}>
                                <DataGrid rows={this.state.users} columns={this.cols} />
                            </div>
                        </div>
                    </div>

                }

            </Box>
        )
    }
}

export default Homepage;