import jwt_decode from "jwt-decode";

class AuthStorage {
    constructor() {

    }

    getToken() {
        if ("token" in localStorage) {
            return localStorage["token"];
        }
        return null;
    }

    getEmail() {
        let token = this.getToken();
        if (token == null) {
            return null;
        }
        console.log(token);
        const decoded = jwt_decode(token);
        return decoded.email;
    }

    getUsername() {
        const email = this.getEmail();
        if (email == null) {
            return null;
        }
        return email.split("@")[0];
    }

    setToken(token) {
        console.log("Updating token in storage");
        localStorage["token"] = token;
    }

    clearToken() {
        delete localStorage["token"];
    }
}

export default AuthStorage;