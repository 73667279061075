import { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

export default function CheckboxList({items, handleToggle}) {

    return (
        <List sx={{ bgcolor: 'background.paper' }}>
            {items.map((user) => {
                const labelId = `checkbox-list-label-${user.id}`;

                return (
                    <ListItem
                        style={{
                            width: "25%",
                            display: "inline-block",
                        }}
                        key={user.id}
                        // secondaryAction={
                        //     <IconButton edge="end" aria-label="comments">
                        //         <CommentIcon />
                        //     </IconButton>
                        // }
                        disablePadding
                    >
                        <ListItemButton role={undefined} onClick={(ev) => handleToggle(user.id, ev.target.checked)} dense>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={user.checked}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={user.name} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}