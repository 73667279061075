import React from 'react';
import DataFetcher from './utils/DataFetcher';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';


class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.dataFetcher = new DataFetcher();

        this.uploadCsv = this.uploadCsv.bind(this);

        this.cols = [
            { field: 'name', headerName: 'Name', width: 250 },
            { field: 'price', headerName: 'Price', width: 100 },
            {
                field: 'weeklyRotationRound1', headerName: 'Weekly rotation - round 1', width: 250, renderCell: (params) => (
                    this.genTableWeeklyRotationButton(params, 1)
                )
            },
            {
                field: 'weeklyRotationRound2', headerName: 'Weekly rotation - round 2', width: 250, renderCell: (params) => (
                    this.genTableWeeklyRotationButton(params, 2)
                )
            },
        ];

        this.state = {
            coffees: {
                rows: [

                ],
            },
        };
    }

    genTableWeeklyRotationButton(params, round) {
        return (
            <Button style={{width: "100%" }} variant="contained" disabled={params.value} onClick={() => {
                //let callback = params.value ? this.dataFetcher.removeFromWeeklyRotation : this.dataFetcher.addToWeeklyRotation;
                this.dataFetcher.setWeeklyRotation(round, params.id).then(() => this.reloadData())
            }} color={params.value ? "error" : "success"}>{params.value ? "In weekly rotation" : "Assign"}</Button>
        )
    
    }

    reloadData() {
        this.dataFetcher.fetchCoffees().then(coffees => {
            console.log(coffees);
            let mapped = coffees.coffees.map(coffee => {
                // if (coffees.hasOwnProperty("weeklyRotation")) {
                //     coffee.weeklyRotation = coffees.weeklyRotation.coffees.includes(coffee._id);
                // }
                coffee.id = coffee._id;
                if (coffees.hasOwnProperty("weeklyRotation")){
                    coffee.weeklyRotationRound1 = coffee.id == coffees.weeklyRotation.round1;
                    coffee.weeklyRotationRound2 = coffee.id == coffees.weeklyRotation.round2;
                }
                return coffee;
            });
            console.log(mapped);
            this.setState({
                coffees: {
                    rows: mapped,
                },
                weeklyRotation: coffees.weeklyRotation,
            });
        });
    }

    componentDidMount() {
        this.reloadData();
    }

    resetFileUploader() {
        let el = document.getElementById("csvFile");
        console.log(el);
        el.reset();
    }

    uploadCsv(event) {
        const f = event.target.files[0];
        const fr = new FileReader();
        fr.onloadend = () => {
            const content = fr.result;
            console.log(content);
            this.dataFetcher.uploadCoffeeFile(content).then(() => this.reloadData());
            event.target.value = "";
        };
        fr.readAsText(f);
    }

    render() {
        return (
            <div style={{ height: 600, width: '100%' }}>
                {/* <ul>
                    {this.state.coffees.rows.map(coffee => <li>{coffee.name} <strong>£{coffee.price}</strong></li>)}
                </ul> */}
                <DataGrid rows={this.state.coffees.rows} columns={this.cols} />
                <Divider />
                <Typography variant="h3" component="div">
                    Import data
                </Typography>
                <Box>
                    <Typography component="div">
                        Coffees can be imported from a csv file of the following format:
                        <Typography style={{ fontStyle: 'italic' }}>
                            Coffee name,price
                        </Typography>
                        Don't include headers.
                    </Typography>
                    <Button variant="contained" component="label">Upload
                        <input id="csvFile" type="file" hidden onChange={this.uploadCsv} />
                    </Button>
                </Box>
            </div>
        )
    }
}

export default Dashboard;