import React from 'react';
import { Navigate } from 'react-router-dom';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import AuthStorage from './utils/AuthStorage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDgXRyBmnGBCBqYARztctad2VyCMUNDCS4",
    authDomain: "figment-coffee-admin.firebaseapp.com",
    projectId: "figment-coffee-admin",
    storageBucket: "figment-coffee-admin.appspot.com",
    messagingSenderId: "419529541835",
    appId: "1:419529541835:web:1253f3f24dda4152a0f34e",
    measurementId: "G-GK45V1M7LW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const provider = new GoogleAuthProvider();



class Login extends React.Component {
    constructor(props) {
        super(props);

        this.launchAuth = this.launchAuth.bind(this);

        this.state = {
            loggedIn: false,
        };

    }

    launchAuth() {
        const self = this;
        const auth = getAuth();
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                console.log(credential);
                const token = credential.idToken;
                console.log("We are here!");
                new AuthStorage().setToken(token);

                self.setState({
                    loggedIn: true
                });
                console.log(token);
                // The signed-in user info.
                const user = result.user;
                // ...
            }).catch((error) => {
                // Handle Errors here.
                console.log(error);
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }

    render() {
        console.log("Re-render!")
        return (
            <div>
                {this.state.loggedIn ?
                    <Navigate to="/" /> :
                    <div>
                        <h1>Login</h1>
                        <h3><button onClick={this.launchAuth}>Login with Firebase</button></h3>
                    </div>
                }

            </div>
        )
    }
}

export default Login;