import React from 'react';
import AuthStorage from './utils/AuthStorage';
import { Navigate, Outlet } from 'react-router-dom';

class PrivateRoute extends React.Component {
    render() {
        const auth = new AuthStorage().getToken() != null;
        return auth ? <Outlet /> : <Navigate to="/login" />;
    }
}

export default PrivateRoute;