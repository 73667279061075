import React from 'react';
import AuthStorage from './utils/AuthStorage';
import DataFetcher from './utils/DataFetcher';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';

class DataExport extends React.Component {
    constructor(props) {
        super(props);

        this.launchExport = this.launchExport.bind(this);

        this.dataFetcher = new DataFetcher();
    }

    launchExport(){
        this.dataFetcher.launchExport().then(res => console.log(res));
    }

    render() {
        return (
            <Box>
                <Button variant="contained" onClick={this.launchExport}>Export orders</Button>
            </Box>
        )
    }
}

export default DataExport;