import React from 'react';
import DataFetcher from './utils/DataFetcher';
import CheckboxList from './CheckedList';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Container, Divider } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const userFilterFuncs = {
    'All': (user) => true,
    'None': (user) => false,
    'Payment registered': (user) => user.paymentValidated,
    'Payment not registered': (user) => !user.paymentValidated,
};

class CampaignLauncher extends React.Component {
    constructor(props) {
        super(props);

        this.launchCampaign = this.launchCampaign.bind(this);
        this.closePreCampaignLaunchDialog = this.closePreCampaignLaunchDialog.bind(this);
        this.handleListToggle = this.handleListToggle.bind(this);
        this.setUsersChecked = this.setUsersChecked.bind(this);
        this.handleCustomMessageBoxChange = this.handleCustomMessageBoxChange.bind(this);

        this.state = {
            weeklyRotation: [],
            loading: true,
            preCampaignLaunchStats: {},
            round: -1,
            preCampaignLaunchDialogIsOpen: false,
            users: [],
            useCustomMessage: false,
            customMessage: "",
        };

        this.dataFetcher = new DataFetcher();
    }
    
    componentDidMount() {
        this.dataFetcher.fetchWeeklyRotation().then(weeklyRotation => this.setState({ weeklyRotation: weeklyRotation, loading: false }));
        this.dataFetcher.fetchStats().then(stats => this.setState({
            users: stats.users.map(user => {
                return {
                    ...user,
                    checked: true,
                    id: user._id,
                };
            })
        }));
    }

    handleListToggle(userId, checked) {
        this.setState(previousState => {
            const currentIndex = previousState.users.findIndex((user) => user.id === userId);
            const newUsers = [...previousState.users];

            if (currentIndex === -1) {
                console.error(`Could not find ${userId} in ${previousState.users.map(user => user.id)}`);
            } else {
                newUsers[currentIndex].checked = checked;
            }
            return { users: newUsers };
        });
    }

    handleCustomMessageBoxChange(ev) {
        this.setState({ customMessage: ev.target.value });
    }

    handleToggleUseCustomMessage(checked) {
        this.setState({ useCustomMessage: checked });
    }

    setUsersChecked(filterFunc) {
        this.setState(prevState => {
            return {
                users: prevState.users.map(user => {
                    return {
                        ...user,
                        checked: filterFunc(user),
                    };
                })
            };
        });
    }

    launchCampaign(round) {
        this.dataFetcher.launchCampaign(
                round,
                this.state.users.filter(user => user.checked).map(user => user.id),
                this.state.useCustomMessage ? this.state.customMessage : undefined)
            .then(res => console.log(`${res.textsSent} texts sent!`));
    }

    openPreCampaignLaunchDialog(round) {
        this.dataFetcher.fetchPreCampaignLaunchStats(round).then(res => {
            this.setState({
                preCampaignLaunchStats: res,
                preCampaignLaunchDialogIsOpen: true,
                round: round,
            });
        });
    }

    closePreCampaignLaunchDialog(round) {
        this.setState({ preCampaignLaunchDialogIsOpen: false });
        if (round) {
            this.launchCampaign(round);
        }
    }

    getIfAvailable(obj, property) {
        return obj.hasOwnProperty(property) ? obj[property] : "";
    }

    render() {
        return (
            <div>
                <Box>
                    <Typography variant="h3">
                        Launch campaign
                    </Typography>
                    <br />
                    <Divider />
                    <br />
                    <Typography variant="h5">
                        Current weekly rotation
                    </Typography>
                    {this.state.loading ?
                        <CircularProgress /> :
                        <Typography component="div">
                            {(this.state.weeklyRotation && Object.keys(this.state.weeklyRotation).length === 0) ?
                                <p>No coffees in the weekly rotation! Go to <a href="/dashboard">the dashboard</a> to set some.</p> :
                                // <ul className="noBulletPoint">
                                //     {this.state.weeklyRotation.map((coffee, index) => (
                                //         <li style={{width: "100%"}} key={index}>{coffee.name}</li>
                                //     ))}
                                // </ul>
                                <Container >

                                    <TableContainer sx={{ maxWidth: 350, marginLeft: "35%" }} component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Round 1</TableCell>
                                                    <TableCell align="right">Round 2</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow className="bigText">
                                                    <TableCell style={{ backgroundColor: "#f5e7d0" }}>{this.state.weeklyRotation.hasOwnProperty("round1") && this.state.weeklyRotation.round1.name}</TableCell>
                                                    <TableCell align="right" style={{ backgroundColor: "#d8d7f7" }}>{this.state.weeklyRotation.hasOwnProperty("round2") && this.state.weeklyRotation.round2.name}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Button variant="contained" onClick={() => this.openPreCampaignLaunchDialog(1)} disabled={!this.state.weeklyRotation.hasOwnProperty("round1")}>Launch</Button>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Button variant="contained" onClick={() => this.openPreCampaignLaunchDialog(2)} disabled={!this.state.weeklyRotation.hasOwnProperty("round2")}>Launch</Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Container>
                            }
                            <br />
                            {Object.entries(userFilterFuncs).map(([label, func]) => {
                                return (
                                    <Button key={label} style={{ marginRight: "10px" }} variant="contained" onClick={() => this.setUsersChecked(func)}>{label}</Button>
                                )
                            })}
                            <br />
                            <div style={{
                                display: "flex",
                                flexFlow: "column wrap",
                                gap: "0 30px",
                                border: "1px solid black",
                                margin: "10px",
                                height: 300, // set the height limit to your liking
                                overflow: "auto"
                            }}>
                                <CheckboxList items={this.state.users} handleToggle={this.handleListToggle} />
                            </div>
                            <Box>
                                <FormControlLabel control={<Checkbox
                                    edge="start"
                                    checked={this.state.useCustomMessage}
                                    onChange={(ev) => this.handleToggleUseCustomMessage(ev.target.checked)}
                                    tabIndex={-1}
                                    disableRipple
                                />} label="Use custom message" />
                                <br />
                                
                                
                                <TextField
                                    id="standard-multiline-flexible"
                                    label="Custom Message"
                                    multiline
                                    maxRows={4}
                                    fullWidth
                                    disabled={!this.state.useCustomMessage}
                                    value={this.state.customMessage}
                                    onChange={this.handleCustomMessageBoxChange}
                                    variant="standard"
                                />
                            </Box>
                        </Typography>
                    }
                    <br />
                </Box>
                <PreCampaignLaunchDialog
                    open={this.state.preCampaignLaunchDialogIsOpen}
                    stats={{
                        ...this.state.preCampaignLaunchStats,
                        textsCount: this.state.users.filter(user => user.checked).length,
                    }}
                    round={this.state.round}
                    customMessage={this.state.customMessage}
                    useCustomMessage={this.state.useCustomMessage}
                    close={this.closePreCampaignLaunchDialog}
                />
            </div>

        )
    }
}

class PreCampaignLaunchDialog extends React.Component {

    constructor(props) {
        super(props)

        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleOk() {
        this.props.close(this.props.round);
    }

    handleCancel() {
        this.props.close();
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
            >
                <DialogTitle>Launch Campaign</DialogTitle>
                <DialogContent>
                    {Object.keys(this.props.stats).length > 0 &&
                        <Typography component="div">
                            Confirm transmission of:
                            <ul>
                                <li><strong>{this.props.stats.textsCount}</strong> texts</li>
                                <li>from number <strong>{this.props.stats.number}</strong></li>
                                <li>for coffee <strong>{this.props.stats.coffee}</strong></li>
                            </ul>
                            {this.props.useCustomMessage && 
                                <div>
                                    <p>With custom message:</p>
                                    <p><strong>{this.props.customMessage}</strong></p>
                                </div>
                            }
                        </Typography>
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.handleCancel} color="error">
                        Cancel
                    </Button>
                    <Button onClick={this.handleOk} color="success">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

}

export default CampaignLauncher;