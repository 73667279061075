import AuthStorage from "./AuthStorage";

// const backendUrl = "http://localhost:3002";
const backendUrl = `https://${process.env.REACT_APP_API_BACKEND}`;
console.log("Backend URL " + backendUrl);

class DataFetcher {
    constructor() {
        this.authStorage = new AuthStorage();

        this.setWeeklyRotation = this.setWeeklyRotation.bind(this);
        this.removeFromWeeklyRotation = this.removeFromWeeklyRotation.bind(this);
    }

    async fetchData(endpoint, method, payload, jsonContent = true) {
        const token = this.authStorage.getToken();
        let data = {
            method: method,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': jsonContent? 'application/json' : 'multipart/form-data'
            },
        }
        if (["POST", "PUT", "DELETE"].includes(method)) {
            data.body = jsonContent ? JSON.stringify(payload) : payload;
        }

        console.log(data);

        let response = await fetch(backendUrl + endpoint, data);
        let json = await response.json();
        return json
    }

    async downloadFile(endpoint) {
        const token = this.authStorage.getToken();
        fetch(backendUrl + endpoint, {
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        })
            .then( res => res.blob() )
            .then( blob => {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'orders.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
        });
    }

    async fetchCoffees() {
        return this.fetchData("/coffees", "GET");
    }

    fetchStats() {
        return this.fetchData("/stats", "GET");
    }

    fetchWeeklyRotation() {
        return this.fetchData("/weeklyRotation", "GET");
    }

    fetchPreCampaignLaunchStats(round) {
        return this.fetchData("/preCampaignLaunchStats", "GET", {round: round});
    }

    async setWeeklyRotation(round, id) {
        return this.fetchData("/weeklyRotation", "PUT", {round: round, id: id});
    }

    async removeFromWeeklyRotation(id) {
        return this.fetchData("/weeklyRotation", "DELETE", {id: id});
    }

    async uploadCoffeeFile(f) {
        return this.fetchData("/uploadCoffeeFile", "POST", {"data": f});
    }

    launchCampaign(round, users, custom_message) {
        return this.fetchData("/launchCampaign", "POST", {round: round, users: users, custom_message: custom_message});
    }

    launchExport() {
        return this.downloadFile("/orders");
    }

    setUseLiveDb(value) {
        return this.fetchData("/useLiveDb", "POST", {useLiveDb: value});
    }
    
    getUseLiveDb() {
        return this.fetchData("/useLiveDb", "GET");
    }
}

export default DataFetcher;
